exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-company-tsx": () => import("./../../../src/pages/company.tsx" /* webpackChunkName: "component---src-pages-company-tsx" */),
  "component---src-pages-find-a-part-tsx": () => import("./../../../src/pages/find_a_part.tsx" /* webpackChunkName: "component---src-pages-find-a-part-tsx" */),
  "component---src-pages-gic-classification-tsx": () => import("./../../../src/pages/gic_classification.tsx" /* webpackChunkName: "component---src-pages-gic-classification-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-tsx": () => import("./../../../src/pages/newsletter.tsx" /* webpackChunkName: "component---src-pages-newsletter-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-under-construction-tsx": () => import("./../../../src/pages/under_construction.tsx" /* webpackChunkName: "component---src-pages-under-construction-tsx" */)
}

